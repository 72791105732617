var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "wuwow-card",
    },
    [
      _c(
        "div",
        {
          staticClass: "wuwow-card-body",
        },
        [
          _c(
            "h4",
            {
              staticClass: "h4-learnRule-title",
            },
            [_vm._v(_vm._s(_vm.$t("learn_rule_title")))]
          ),
          _c(
            "p",
            {
              staticClass: "txt-product-name",
            },
            [_vm._v(_vm._s(_vm.contract_info.product_name))]
          ),
          _c(
            "ul",
            {
              staticClass: "ul-contract-info",
            },
            [
              _vm.contract_info.product_id == _vm.permanently_product_id
                ? [
                    _c(
                      "li",
                      {
                        staticClass: "li-contract-info",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("purchase_lesson")) +
                            ": " +
                            _vm._s(_vm.$t("unlimited_lessons"))
                        ),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "li-contract-info",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("total_session")) +
                            ": " +
                            _vm._s(_vm.$t("unlimited_lessons"))
                        ),
                      ]
                    ),
                  ]
                : [
                    _vm.isTransferContract()
                      ? [
                          _c(
                            "li",
                            {
                              staticClass: "li-contract-info",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("transfer_session")) +
                                  ": " +
                                  _vm._s(_vm.contract_info.gift_session) +
                                  _vm._s(_vm.$t("lesson"))
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "li-contract-info",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("transfer_term")) +
                                  ": " +
                                  _vm._s(_vm.$t("original_contract_period"))
                              ),
                            ]
                          ),
                        ]
                      : [
                          _c(
                            "li",
                            {
                              staticClass: "li-contract-info",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("purchase_lesson")) +
                                  ": " +
                                  _vm._s(_vm.contract_info.num_session) +
                                  _vm._s(_vm.$t("lesson"))
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "li-contract-info",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("lesson_period")) +
                                  ": " +
                                  _vm._s(_vm.contract_info.orig_term) +
                                  _vm._s(_vm.$t("a_month"))
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "li-contract-info",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("gift_session")) +
                                  ": " +
                                  _vm._s(_vm.contract_info.gift_session) +
                                  _vm._s(_vm.$t("lesson"))
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "li-contract-info",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("gift_term")) +
                                  ": " +
                                  _vm._s(_vm.contract_info.gift_term) +
                                  _vm._s(_vm.$t("a_month"))
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "li-contract-info",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("total_session")) +
                                  ": " +
                                  _vm._s(_vm.contract_info.total_session) +
                                  _vm._s(_vm.$t("lesson"))
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "li-contract-info",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("total_term")) +
                                  ": " +
                                  _vm._s(_vm.contract_info.total_term) +
                                  _vm._s(_vm.$t("a_month"))
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "li-contract-info",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("cash_discount_amount")) +
                                  ": $" +
                                  _vm._s(
                                    Number(_vm.contract_info.bd_quota) +
                                      Number(
                                        _vm.contract_info.one_pay_disc_price
                                      ) +
                                      Number(_vm.contract_info.disc_price) +
                                      Number(_vm.contract_info.other_disc) +
                                      Number(_vm.contract_info.renewal_disc)
                                  )
                              ),
                            ]
                          ),
                        ],
                  ],
            ],
            2
          ),
          _vm.contract_info.o2o !== 1 && !_vm.isTransferContract()
            ? [
                _vm.contract_info.product_id == _vm.permanently_product_id
                  ? [
                      _c(
                        "p",
                        {
                          staticClass: "txt-product-price",
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("course_discount")) +
                              " " +
                              _vm._s(_vm.$t("system_set_fee")) +
                              _vm._s(_vm.$t("per_fee")) +
                              " $3000 " +
                              _vm._s(_vm.$t("dollar")) +
                              " + " +
                              _vm._s(_vm.$t("monthly_payment")) +
                              "$1990 " +
                              _vm._s(_vm.$t("dollar"))
                          ),
                        ]
                      ),
                    ]
                  : [
                      _c(
                        "p",
                        {
                          staticClass: "txt-product-price",
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("course_discount")) +
                              " $" +
                              _vm._s(_vm.contract_info.selling_price)
                          ),
                        ]
                      ),
                    ],
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }