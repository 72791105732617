var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "wuwow-card",
    },
    [
      _c(
        "div",
        {
          staticClass: "wuwow-card-body",
        },
        [
          _c("div", {
            staticClass: "are-learnRule-content v-html",
            domProps: {
              innerHTML: _vm._s(_vm.contract_info.contract),
            },
          }),
          _c(
            "div",
            {
              staticClass: "are-learnRule-control",
            },
            [
              _vm.contract_info.learn_rule == "0000-00-00 00:00:00" ||
              !_vm.contract_info.learn_rule
                ? [
                    _c(
                      "b-form-checkbox",
                      {
                        staticClass: "mr-2",
                        model: {
                          value: _vm.check_button,
                          callback: function ($$v) {
                            _vm.check_button = $$v
                          },
                          expression: "check_button",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("i_agree_reading_learn_rule")))]
                    ),
                    _c("br"),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary mt-3",
                        attrs: {
                          type: "button",
                          disabled: !_vm.check_button,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.check_contract(
                              _vm.contract_info.customer_orders_id
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("agree")))]
                    ),
                  ]
                : [
                    _c(
                      "b-alert",
                      {
                        attrs: {
                          variant: "info",
                          show: "",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("you_have")) +
                            " " +
                            _vm._s(_vm.contract_info.learn_rule) +
                            " " +
                            _vm._s(_vm.$t("read_learn_term_notice"))
                        ),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-primary",
                        attrs: {
                          type: "button",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toContractPage()
                          },
                        },
                      },
                      [_vm._v("下載學習條款")]
                    ),
                  ],
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }