var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "reg-learnRule",
    },
    [
      [
        _c("learn-rule-content", {
          attrs: {
            contract_info: this.$route.params.data,
          },
        }),
        _c("agree-learn-rule", {
          attrs: {
            contract_info: this.$route.params.data,
          },
        }),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }